import { OKTA_CLIENT_ID, OKTA_ISSUER_URL } from "./environment";

const oktaConfig = {
  okta:{
  clientId: OKTA_CLIENT_ID,
  redirectUri: window.location.origin+`/authorization-code/callback`,
  issuer: OKTA_ISSUER_URL,  
  scopes: ["openid", "profile", "email","offline_access"],
  pkce: true,
},
issuer: OKTA_ISSUER_URL,  
};

export default oktaConfig;


